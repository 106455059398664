.app {
  text-align: center;

  .lang {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      max-width: 260px;
      margin-bottom: 16px;
    }
  }

  .stars {
    margin: 22px 0;
    span {
      display: inline-block;
      margin-right: 12px;
      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        svg {
          color: orange;
        }
      }
    }
    svg {
      color: lightgray;
      font-size: 36px;
    }
  }

  .remark {
    textarea {
      width: 80vw;
      max-width: 420px;
    }
  }
}

.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;

  .google {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      width: 80vw;
      max-width: 420px;

      b {
        color: rgb(209, 61, 61);
      }
    }
  }
}
